.type-element-root {
    color: rgb(17, 0, 77);
    display: block;
    position: relative;
    padding: 25px 15px;
    margin: 5px;
    overflow: hidden;
    border-radius: 10px;
    border: 2px solid rgb(17, 0, 77);
    box-shadow: 0 0px 0px 0 rgba(17, 0, 77, 0.24);
    transition: box-shadow .2s ease;
}

.type-element-root:hover,
.type-element-root:focus .type-element-root:active,
.type-element-root.active {
    color: rgb(17, 0, 77);
    background-color: rgb(17, 0, 77);
    box-shadow: 0 8px 16px 0 rgba(17, 0, 77, 0.24);
}
.type-element-root.disabled {
    color: rgb(181, 179, 185);
    background-color: rgb(181, 179, 185);
    box-shadow: none;
    border: 2px solid rgb(181, 179, 185);
}
.type-element-root .type-element-child {
    padding: 20px;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    transition: box-shadow .2s ease;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.type-element-root .type-element-child.type-scaler {
    background-image: url("https://alphapgs.pages.dev/static/icons/widget-types/e-learning.png");
}

.type-element-root:hover .type-element-child.type-scaler,
.type-element-root.active .type-element-child.type-scaler {
    background-image: url("https://alphapgs.pages.dev/static/icons/widget-types/e-learning-hover.png");
}

.type-element-root .type-element-child.type-table {
    background-image: url("https://alphapgs.pages.dev/static/icons/widget-types/cells.png");
}

.type-element-root:hover .type-element-child.type-table,
.type-element-root.active .type-element-child.type-table,
.type-element-root.disabled .type-element-child.type-table {
    background-image: url("https://alphapgs.pages.dev/static/icons/widget-types/cells-hover.png");
}

.type-element-root .type-element-child.type-log {
    background-image: url("https://alphapgs.pages.dev/static/icons/widget-types/duration.png");
}

.type-element-root:hover .type-element-child.type-log,
.type-element-root.active .type-element-child.type-log {
    background-image: url("https://alphapgs.pages.dev/static/icons/widget-types/duration-hover.png");
}

.type-element-root .type-element-child.type-chart {
    background-image: url("https://alphapgs.pages.dev/static/icons/widget-types/line-chart.png");
}

.type-element-root:hover .type-element-child.type-chart,
.type-element-root.active .type-element-child.type-chart {
    background-image: url("https://alphapgs.pages.dev/static/icons/widget-types/line-chart-hover.png");
}

.widget-tools {
    background-color: #f1edfd8f;
    border-radius: 3rem;
    padding: 1px;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    box-shadow: 0 1px 10px 0 rgba(62, 12, 241, 0.144);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: opacity .2s ease;
}

.react-grid-item:hover .widget-tools {
    opacity: 1;
}

.scaler {
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
}

.scaler-content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    vertical-align: middle;
}

.scaler-value {
    /* dynamic font size relative to the width*/ 
    /* font-size: calc(1.5rem + (1.5 - 1.5 * (100vw - 300px) / (1600 - 300)) * (100vw - 300px) / (1600 - 300)); */
    font-size: calc(1.5rem + (1.5 - 1.5 * (100vw - 320px) / (1920 - 320)));
    font-weight: bold;
    color: rgb(17, 0, 77);
}
.scaler-budget{
    position: absolute;
    top: 5%;
    right: 5%;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #31af00;
}
/* red flashing animation background */
.scaler-budget.red {
    animation: blinker 0.5s linear infinite;
}
/* animation keyframes */
@keyframes blinker {
    0% {
        background-color: #ffffff;
    }
    50% {
        background-color: #ff0000;
    }
    100% {
        background-color: #ffffff;
    }

}

